import { Cart, CentPrecisionMoney, Money, TypedMoney } from "@commercetools/platform-sdk";
import { useQueries, useQuery } from "@tanstack/react-query";
import { PriceResponse, ProductConfiguration } from "@wttb/product-configurator";
import { useSession } from "next-auth/react";
import { useMemo } from "react";

import { getPriceForConfig } from "@/clients/product-config/product";
import { useMe } from "@/features/account/hooks/use-me";

export function transformCents(value: Money | TypedMoney) {
  let fractionDigits = 2;
  if ("fractionDigits" in value && Number.isInteger(value.fractionDigits)) {
    fractionDigits = value.fractionDigits;
  }

  return value.centAmount / Math.pow(10, fractionDigits);
}

export function sumPrices(...prices: (Money | TypedMoney | CentPrecisionMoney | undefined)[]) {
  // find the first defined price to be used as a base
  const basePrice = prices.find(Boolean);
  if (!basePrice) return;

  const centAmount = prices.reduce((total, price) => total + (price?.centAmount ?? 0), 0);
  return { ...basePrice, centAmount };
}

export function getUnitPriceFractionDigits(price: Money | TypedMoney, unit: Money | TypedMoney) {
  let priceFractionDigits = 2;
  if ("fractionDigits" in price && Number.isInteger(price.fractionDigits)) {
    priceFractionDigits = price.fractionDigits;
  }

  let unitFractionDigits = 2;
  if ("fractionDigits" in unit && Number.isInteger(unit.fractionDigits)) {
    unitFractionDigits = unit.fractionDigits;
  }

  const minimumAmount = 1 / Math.pow(10, priceFractionDigits);
  let fractionDigits = priceFractionDigits;
  if (unitFractionDigits - priceFractionDigits > 0) {
    const amount = transformCents(unit);
    if (minimumAmount > amount) {
      fractionDigits = unitFractionDigits;
    }
  }

  return fractionDigits;
}

export function formatNumberPrice(price: number, currencyCode: string, locale: string = "en") {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  return formatCurrency.format(price);
}

export function formatPrice(
  value: Money | TypedMoney | undefined,
  locale: string = "en",
  options?: Intl.NumberFormatOptions,
) {
  if (!value) {
    return "";
  }

  const { centAmount, currencyCode } = value;

  if (typeof centAmount !== "number" || !currencyCode) {
    return "";
  }

  const price = transformCents(value);

  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    ...options,
  });

  return formatCurrency.format(price);
}

export function useFormattedPrice(
  value: Money | TypedMoney | undefined,
  locale: string = "en",
  options?: Intl.NumberFormatOptions,
) {
  return useMemo(() => {
    return formatPrice(value, locale, options);
  }, [value, locale, options]);
}

export function useDefaultPriceWithLoyalty({
  sku,
  config = null,
  includeQuantityCalculation = false,
  uniqueHashKey,
  enabled = true,
  cartId,
}: {
  sku: string;
  config?: ProductConfiguration | null;
  includeQuantityCalculation?: boolean;
  uniqueHashKey?: string;
  enabled?: boolean;
  cartId?: string;
}) {
  const { data: session } = useSession();
  const { data: customer } = useMe(session);

  return useQuery(
    ["product-config-price", sku, config, session?.customerId, cartId, uniqueHashKey],
    () =>
      getPriceForConfig({
        sku,
        config,
        includeQuantityCalculation: includeQuantityCalculation,
        metadata: {
          cartId,
          customerId: session?.customerId,
          loyaltyTier: customer?.custom?.fields.loyaltyTierKey,
        },
      }),
    {
      retry: false,
      enabled: enabled && !!sku && !!config,
      keepPreviousData: true,
    },
  );
}

// Parallel fetch of Cart LineItem prices and delivery options
export function useCartItemPrices({ cart }: { cart: Cart }) {
  return useQueries({
    queries: cart.lineItems.map((lineItem) => {
      const sku = lineItem.variant.sku!;
      const config = JSON.parse(lineItem.custom?.fields.productConfig ?? "{}");

      return {
        queryKey: ["product-config-price", sku, undefined, lineItem.id],
        queryFn: () => getPriceForConfig({ sku, config }),
        select: (data: PriceResponse | null) => ({ ...data, lineItemId: lineItem.id }),
        retry: false,
      };
    }),
  });
}
