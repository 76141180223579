import { PriceResponse, ProductConfiguration, ProductSku } from "@wttb/product-configurator";

import http from "@/clients/http";
import { config as env } from "@/config";

export type GetPriceRequest = {
  sku: string;
  config: ProductConfiguration | null;
  includeQuantityCalculation?: boolean;
  metadata?: {
    cartId?: string;
    customerId?: string;
    couponCodes?: string[];
    loyaltyTier?: string;
  };
};

export const getProductDataBySKU = async (productSku: string) => {
  const product = await http.get<ProductSku>(`${env.NEXT_PUBLIC_WTTB_API}/api/sku/${productSku}`);
  return {
    attributes: product.attributes,
    warnings: product.warnings,
    calculator: product.calculator,
  };
};

export const getPriceForConfig = async ({
  sku,
  config,
  includeQuantityCalculation = false,
  metadata,
}: GetPriceRequest): Promise<PriceResponse | null> => {
  const res = await http.post(
    `${env.NEXT_PUBLIC_WTTB_API}/api/price`,
    { sku, productConfig: config, includeQuantityCalculation, metadata },
    { Accept: "application/json" },
  );
  const data = await res.json();

  // We should throw an error to reject promise
  if (res.status !== 200) {
    throw new Error(
      `An error occurred while calculating price: ${data?.message || JSON.stringify(data)}`,
    );
  }

  return data;
};

export const getThicknessesMap = async () => {
  return await http.get<Record<string, number>>(
    `${env.NEXT_PUBLIC_WTTB_API}/api/configurator/attribute-values/thicknesses-map`,
  );
};
