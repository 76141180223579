import { Cart } from "@commercetools/platform-sdk";
import { setTag } from "@sentry/nextjs";
import { useQuery } from "@tanstack/react-query";
import { OrderIntent } from "@wttb/shared";
import { getServerSession, Session } from "next-auth";
import { getSession as getClientSession, useSession } from "next-auth/react";

import { authOptions } from "@/app/api/auth/[...nextauth]/auth";
import { createApiClient } from "@/clients/commercetools";

const getSession = async (): Promise<Session | null> => {
  if (typeof window == "undefined") {
    return await getServerSession(authOptions);
  } else {
    return await getClientSession();
  }
};

export const fetchCart = async (session: Session | null): Promise<Cart | null> => {
  if (!session) session = await getSession();
  if (!session) return null;

  const cartsApi = createApiClient(session.accessToken!).me().carts();
  const query: Parameters<(typeof cartsApi)["get"]>[0] = {
    queryArgs: {
      limit: 1,
      expand: ["customLineItems[*].taxCategory"],
      where: [`cartState="Active" and custom(fields(intent!="${OrderIntent.Reprint}"))`],
    },
  };

  const carts = await cartsApi.get(query).execute();

  if (carts.body.results[0]) {
    setTag("cartId", carts.body.results[0].id);
  } else {
    setTag("cartId", undefined!);
  }

  return carts.body.results[0] || null;
};

export function useCart() {
  const { data: session } = useSession();
  return useQuery<Cart | null, Error>(["CART"], () => fetchCart(session));
}
