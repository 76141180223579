"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcProductConfigValueChange = exports.getAttributeValueByIdOrIds = exports.getDeliveryOption = exports.getPriceForConfig = exports.removeAttributeWarnings = exports.getAttributeWarningsHash = exports.removeConfigValueIncompatibleValues = exports.getAttributesHash = exports.generatePartialConfig = exports.getIncompatibilitiesHash = exports.generateInitialConfig = exports.validateConfig = exports.ProductAttributeKeys = void 0;
exports.ProductAttributeKeys = {
    Quantity: "quantity",
    SizeRun: "sizeRun",
    Size: "size",
    Pages: "pages",
    Spine: "spine",
    Orientation: "orientation",
    Cover: "cover",
    Inner: "inner",
    CoverMaterial: "coverMaterial",
    CoverMaterialWeight: "coverMaterialWeight",
    CoverLamination: "coverLamination",
    InnerMaterial: "innerMaterial",
    InnerMaterialWeight: "innerMaterialWeight",
    InnerColors: "innerColors",
    SequentialNumbering: "sequentialNumbering",
    SequentialNumberingValue: "sequentialNumberingValue",
};
var validateStringOrNumber = function (v) {
    return ((typeof v === "number" && isFinite(v)) ||
        (typeof v === "string" && v.length));
};
var validateConfig = function (config) {
    if (!config)
        return false;
    if (!!config[exports.ProductAttributeKeys.SequentialNumbering] &&
        !config[exports.ProductAttributeKeys.SequentialNumberingValue])
        return false;
    return Object.keys(config).every(function (key) {
        var value = config[key];
        if (key === exports.ProductAttributeKeys.SizeRun) {
            return (typeof value === "object" &&
                Object.values(value).every(validateStringOrNumber));
        }
        if (Array.isArray(value))
            return value.every(validateStringOrNumber);
        return (typeof value !== "undefined" &&
            (validateStringOrNumber(value) ||
                typeof value === "boolean"));
    });
};
exports.validateConfig = validateConfig;
var getAttributeValueById = function (attribute, id) {
    var defaultValue = attribute.values.find(function (val) { return val.id === id; });
    if (!defaultValue)
        return;
    return defaultValue[attribute.returnKey];
};
var getSizeRunOption = function (attribute) {
    return attribute.values.reduce(function (acc, value) {
        acc[value.id] = value.id === attribute.defaultValueId ? 1 : 0;
        return acc;
    }, {});
};
var generateInitialConfig = function (options) {
    return options.reduce(function (acc, option) {
        if (!option.required && !option.defaultValueId)
            return acc;
        if (option.key === exports.ProductAttributeKeys.SizeRun) {
            acc[option.key] = getSizeRunOption(option);
            return acc;
        }
        var value = getAttributeValueById(option, option.defaultValueId);
        if (typeof value === "undefined")
            return acc;
        acc[option.key] = option.multiple
            ? [value]
            : value;
        return acc;
    }, {});
};
exports.generateInitialConfig = generateInitialConfig;
var getIncompatibilitiesHash = function (options) {
    var incompatibleValues = options.flatMap(function (attr) { return attr.incompatibleValues; });
    return incompatibleValues.reduce(function (acc, val) {
        var attributeValueId = val.attributeValueId;
        acc[attributeValueId] = acc[attributeValueId]
            ? __spreadArray(__spreadArray([], acc[attributeValueId], true), [val], false) : [val];
        return acc;
    }, {});
};
exports.getIncompatibilitiesHash = getIncompatibilitiesHash;
var generatePartialConfig = function (selectedOptions, attributesHash) {
    var partialConfig = Object.keys(selectedOptions).reduce(function (acc, key) {
        var _a;
        var queryValue = selectedOptions[key];
        if (queryValue === null)
            return acc;
        var selectedValue = Array.isArray(queryValue)
            ? queryValue.map(function (valueId) { var _a; return (_a = attributesHash[key]) === null || _a === void 0 ? void 0 : _a[valueId]; })
            : typeof queryValue === "string"
                ? (_a = attributesHash[key]) === null || _a === void 0 ? void 0 : _a[queryValue]
                : null;
        if (Object.values(exports.ProductAttributeKeys).includes(key) && !selectedValue) {
            acc[key] = selectedOptions[key];
        }
        if (selectedValue) {
            acc[key] = Array.isArray(selectedValue)
                ? selectedValue.map(function (value) { return value === null || value === void 0 ? void 0 : value.value; })
                : selectedValue.value;
        }
        return acc;
    }, {});
    return partialConfig;
};
exports.generatePartialConfig = generatePartialConfig;
var getAttributesHash = function (options) {
    return options.reduce(function (acc, attr) {
        var valuesHash = attr.values.reduce(function (acc, value) {
            acc[value.id] = {
                name: value.display,
                value: value[attr.returnKey],
                isRecommended: value.isRecommended,
                settings: value.settings,
                isNew: value.isNew,
            };
            return acc;
        }, {});
        acc[attr.key] = __assign({}, valuesHash);
        return acc;
    }, {});
};
exports.getAttributesHash = getAttributesHash;
var removeConfigValueIncompatibleValues = function (key, incompatibilities) {
    return Object.keys(incompatibilities).reduce(function (acc, attributeKey) {
        acc[attributeKey] = acc[attributeKey].filter(function (val) { return val.attribute.key !== key; });
        return acc;
    }, incompatibilities);
};
exports.removeConfigValueIncompatibleValues = removeConfigValueIncompatibleValues;
var getAttributeWarningsHash = function (warnings) {
    return warnings.reduce(function (acc, warn) {
        var _a;
        if (!acc[warn.attribute.key]) {
            acc[warn.attribute.key] = (_a = {},
                _a[warn.valueId] = warn,
                _a);
            return acc;
        }
        acc[warn.attribute.key][warn.valueId] = warn;
        return acc;
    }, {});
};
exports.getAttributeWarningsHash = getAttributeWarningsHash;
var removeAttributeWarnings = function (key, warnings) {
    return warnings.filter(function (_a) {
        var attribute = _a.attribute;
        return attribute.key !== key;
    });
};
exports.removeAttributeWarnings = removeAttributeWarnings;
var getPriceForConfig = function (_a) {
    var url = _a.url, sku = _a.sku, config = _a.config, includeQuantityCalculation = _a.includeQuantityCalculation, customerTier = _a.customerTier;
    return __awaiter(void 0, void 0, void 0, function () {
        var res, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(url, {
                        method: "POST",
                        body: JSON.stringify({
                            sku: sku,
                            productConfig: config,
                            includeQuantityCalculation: includeQuantityCalculation,
                            customerTier: customerTier,
                        }),
                        headers: { Accept: "application/json" },
                    })];
                case 1:
                    res = _b.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _b.sent();
                    // We should throw an error to reject promise
                    if (res.status !== 200) {
                        throw new Error("An error occurred while calculating price: ".concat((data === null || data === void 0 ? void 0 : data.message) || JSON.stringify(data)));
                    }
                    return [2 /*return*/, data];
            }
        });
    });
};
exports.getPriceForConfig = getPriceForConfig;
var getDeliveryOption = function (priceResult, currentOption) {
    var _a, _b;
    if (!((_a = priceResult === null || priceResult === void 0 ? void 0 : priceResult.deliveryOptions) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    var options = priceResult.deliveryOptions;
    if (!currentOption) {
        // select "Standard" or free delivery option by default
        return (options.find(function (option) { return option.name === "Standard"; }) ||
            options.find(function (option) { return option.price.centAmount === 0; }) ||
            options[0]);
    }
    return ((_b = options.find(function (option) { return option.deliveryRuleId === currentOption.deliveryRuleId; })) !== null && _b !== void 0 ? _b : null);
};
exports.getDeliveryOption = getDeliveryOption;
var getAttributeValueByIdOrIds = function (key, valueId, attributesHash) {
    var _a;
    if (Array.isArray(valueId)) {
        return valueId.map(function (id) { var _a; return (_a = attributesHash[key][id]) === null || _a === void 0 ? void 0 : _a.value; });
    }
    else {
        return (_a = attributesHash[key][valueId]) === null || _a === void 0 ? void 0 : _a.value;
    }
};
exports.getAttributeValueByIdOrIds = getAttributeValueByIdOrIds;
/**
 * Calculates a new compatible product configuration by replacing incompatible values
 * with compatible ones based on the provided value change.
 *
 * @param {CalcProductConfigValueChangeParams} params - The parameters for calculating the value change.
 * @param {Record<string, IncompatibleValue[]>} params.incompatibilitiesHash - The incompatibilities hash.
 * @param {ProductConfiguration} params.productConfig - The current product configuration.
 * @param {Record<string, Record<string, ProductAttribute>>} params.attributesHash - The attributes hash.
 * @param {string} params.valueId - The ID of the value to calculate the change for.
 * @param {string} params.key - The key of the value to calculate the change for.
 * @returns {ProductConfiguration} - The new compatible product configuration.
 */
var calcProductConfigValueChange = function (_a) {
    var _b;
    var incompatibilitiesHash = _a.incompatibilitiesHash, productConfig = _a.productConfig, attributesHash = _a.attributesHash, valueId = _a.valueId, key = _a.key;
    var newConfigValues = {};
    (_b = incompatibilitiesHash[valueId]) === null || _b === void 0 ? void 0 : _b.forEach(function (inc) {
        var currentSelectedKey = inc.incompatibleAttribute.key;
        var currentSelectedValueId = productConfig[currentSelectedKey];
        if (typeof currentSelectedValueId === "string") {
            var isCurrentSelectedValueIncompatible = inc.attribute.key === key &&
                inc.valueIds.includes(currentSelectedValueId);
            if (isCurrentSelectedValueIncompatible) {
                var firstCompatible = Object.keys(attributesHash[inc.incompatibleAttribute.key]).find(function (val) { return !inc.valueIds.includes(val); });
                if (firstCompatible) {
                    newConfigValues[inc.incompatibleAttribute.key] = firstCompatible;
                }
            }
        }
    });
    return newConfigValues;
};
exports.calcProductConfigValueChange = calcProductConfigValueChange;
